<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader tag="div">
          <CIcon name="cil-grid"/>
          Cập nhật quốc gia
        </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol lg="6">
                <ValidationProvider name="Tên quốc gia" rules="required" v-slot="{ errors, valid , touched }">
                  <BaseInput label="Tên quốc gia" horizontal :value.sync="detailItem.ten" :is-valid="valid"
                             :touched="touched" :invalid-feedback="errors"/>
                </ValidationProvider>
              </CCol>
              <CCol lg="6">
                <ValidationProvider name="Mã quốc gia" rules="required" v-slot="{ errors, valid , touched }">
                  <BaseInput label="Mã quốc gia" horizontal :value.sync="detailItem.ma" :is-valid="valid"
                             :touched="touched" :invalid-feedback="errors"/>
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow>
                  <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                  <CCol sm="9" class="form-inline">
                    <CInputCheckbox label="Hoạt động" :checked.sync="detailItem.trangThai"/>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
          <CElementCover v-if="isLoading"/>
        </CCardBody>
        <CCardFooter tag="div" class="text-right">
          <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
          <CButton color="primary" @click="saveItem" :disabled="isSaving">Lưu lại</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { GET_QUOCGIA, UPDATE_QUOCGIA } from '@/store/modules/quoc-gia/actionTypes'

export default {
  name: 'QuocGiaUpdate',
  data () {
    return {
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      optionsTinhThanh: [],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('quocGia', {
      isSaving: 'isSaving',
      responseResult: 'responseResult',
      detailItem: 'detailItem'
    })
  },
  methods: {
    ...mapActions('quocGia', {
      getItem: GET_QUOCGIA,
      updateItem: UPDATE_QUOCGIA
    }),
    async saveItem () {
      await this.updateItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/danh-muc/quoc-gia' })
      }
    },
    cancel () {
      this.$router.push({ path: '/danh-muc/quoc-gia' })
    }
  },
  async mounted () {
    this.isLoading = true
    await this.getItem(this.$route.params.id)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.isLoading = false
  }
}
</script>
